import React from "react";
import styled from "styled-components";
import "../styling/styles.css";

const Wrapper = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  width: 448px;
  margin: 32px 0px 0px 361px;
`;
const Text = styled.p`
    color: #667085;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    font-family: inter, sans-serif;
    letter-spacing: .35px;
`;

export default function SmsConsent(props) {
    return (
        <Wrapper>
            <Text>
                To text you information about quotes and orders,{" "}
                we need your consent to receive text messages from 
                Case Parts via our SMS service provider, clerk.chat.
            </Text>
            <Text>
                Enter <b>your phone number</b> and click <b>Opt-in</b> to
                enable SMS texting with Case Parts.
            </Text>
            <br/>
            <iframe
                src='https://clerk.chat/misc/sms-opt-in/?widgetId=b42fe53c-8cf1-48d5-bdd7-5fed3ccd2902'
                style={{
                    margin: "0 auto",
                    border: "none",
                    background: "xffffff",
                    display: "flex",
                    height: "100%",
                    width: "100%",
                    minWidth: "320px",
                    maxWidth: "440px",
                    minHeight: "654px",
                }} id='clerk-opt-in-form'
                title='SMS Opt-In form'
            ></iframe>
        </Wrapper>
    );
}
