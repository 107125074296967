import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Redirect, Switch, useLocation } from "react-router-dom";
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import AppProvider from "./context/AppProvider";
import MainLayout from "./ui/layout/MainLayout";
import PartList from "./ui/pages/PartList";
import PartPage from "./ui/pages/PartPage";
import CatalogPage from "./ui/pages/CatalogPage";
import Home from "./ui/pages/Home";
import Version from "./ui/pages/Version";
import ShoppingCart from "./ui/pages/ShoppingCart";
import Checkout from "./ui/pages/Checkout";
import Login from "./ui/pages/Login";
import About from "./ui/pages/info/About";
import Faq from "./ui/pages/info/Faq";
import Cpc from "./ui/pages/info/Cpc";
import Csr from "./ui/pages/info/Csr";
import Policies from "./ui/pages/info/Policies";
import Returns from "./ui/pages/info/Returns";
import SalesTax from "./ui/pages/info/SalesTax";
import Privacy from "./ui/pages/info/Privacy";
import Terms from "./ui/pages/info/Terms";
import Contact from "./ui/pages/info/Contact";
import AcctForms from "./ui/pages/info/AccountingForms";
import NotFound from "./ui/pages/NotFound";
import SmsConsent from "./ui/pages/SmsConsent";

import Thankyou from "./ui/pages/Thankyou";
import RequestStatus from "./requests/pages/RequestStatus";
import RequestDashboard from "./requests/pages/RequestDashboard";
import RequestsChart from "./requests/pages/RequestsChart";
import OrdersChart from "./requests/pages/OrdersChart";
import RequestsReport from "./requests/pages/RequestsReport";
import OrdersReport from "./requests/pages/OrdersReport";
import Legal from "./ui/pages/Legal";
import { isMobile } from "react-device-detect";
import * as loupe from "./Loupe.JavaScript.Agent";
import CustomParts from "./customParts/CustomParts";
import GasketWizard from "./customParts/gasket/GasketWizard";
import ProfileSelector from "./customParts/gasket/ProfileSelector";
import WireWizard from "./customParts/warmerWire/WireWizard";
import HeaterWizard from "./customParts/heater/HeaterWizard";
import DoorWizard from "./customParts/door/DoorWizard";
import BoardWizard from "./customParts/board/BoardWizard";
import ShelfWizard from "./customParts/shelf/ShelfWizard";
import useSessionStorage from "./context/useSessionStorage";

// Setup FontAwesome library by declaring the icons we use so it can optimize our bundle size
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronUp, faChevronDown, faPhone } from "@fortawesome/pro-regular-svg-icons";
import { faSortUp, faSortDown } from "@fortawesome/pro-solid-svg-icons";
import { faChevronDoubleUp, faChevronDoubleDown } from "@fortawesome/pro-regular-svg-icons";
import { faInfoCircle as faInfoCircleRegular } from "@fortawesome/pro-regular-svg-icons";
import { faInfoCircle as faInfoCircleSolid } from "@fortawesome/pro-solid-svg-icons";
import { faLightbulbOn, faArrowLeft, faWarehouseAlt } from "@fortawesome/pro-solid-svg-icons";
import { faLocationDot } from "@fortawesome/pro-solid-svg-icons";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";
import { faEnvelope, faBooks, faBuilding, faComments, faShoppingCart } from "@fortawesome/pro-regular-svg-icons";
import { faHourglassStart, faHourglassHalf, faHourglassEnd, faPlay, faStop } from "@fortawesome/pro-regular-svg-icons";
import {
  faPauseCircle,
  faStopwatch,
  faClock,
  faCheck,
  faEllipsisV,
  faWindowClose,
  faQuestion,
  faCartCircleArrowDown
} from "@fortawesome/pro-regular-svg-icons";
import AppSettings from "./AppSettings";
library.add({
  faChevronDoubleUp,
  faChevronDoubleDown,
  faChevronUp,
  faChevronDown,
  faPhone,
  faArrowLeft,
  faSortUp,
  faSortDown,
  faInfoCircleRegular,
  faInfoCircleSolid,
  faLocationDot,
  faExclamationTriangle,
  faLightbulbOn,
  faEnvelope,
  faBooks,
  faBuilding,
  faWarehouseAlt,
  faComments,
  faCartCircleArrowDown,
  faShoppingCart,
  faHourglassStart,
  faHourglassHalf,
  faHourglassEnd,
  faPauseCircle,
  faStopwatch,
  faClock,
  faCheck,
  faEllipsisV,
  faWindowClose,
  faQuestion,
  faPlay,
  faStop
});

const alertOptions = {
  timeout: 5000,
  position: "bottom center"
};

export default function App(props) {
  // This is all about defering as much initialization as possible until after
  // we determine whether we need to redirect to the mobile site.
  const [readyToLoad, setReadyToLoad] = useState(false);

  useEffect(() => {
    if (readyToLoad) {
      loupe.setCORSOrigin(AppSettings.Loupe);
      loupe.Information(`Initializing ${AppSettings.AppVersion}...`);

      // Load an enable Chatlio
      if (AppSettings.EnableChatlio) {
        const script = document.createElement("script");
        script.src = "/chatlio.js";
        script.async = true;
        document.body.appendChild(script);
        document.addEventListener("chatlio.firstMessageSent", function (event) {
          console.log("[chatlio.firstMessageSent] event: %o", event); // Do something interesting
        });
        document.addEventListener("chatlio.messageReceived", function (event) {
          console.log("[chatlio.messageReceived] event: %o", event); // Do something interesting
        });
        document.addEventListener("chatlio.messageSent", function (event) {
          console.log("[chatlio.messageSent] event: %o", event); // Do something interesting
        });
        document.addEventListener("chatlio.preChatInfoSubmitted", function (event) {
          console.log("[chatlio.preChatInfoSubmitted] event: %o", event.data); // Do something interesting
        });
        document.addEventListener("chatlio.conversationEnded", function (event) {
          console.log("[chatlio.conversationEnded] The widget fired a conversationEnded event: %o", event);
        });
      }
    }
  }, [readyToLoad]);

  // We want to redirect mobile devices to the mobile site, but if they then click the
  // link to return to the fullsite, we want to respect that.  We accomplish that by
  // passing a query parameter: fullsite=true.  We then store that parameter is session storage
  // so that any reloads of the browser don't trigger a redirect to the mobile site.
  var fullsiteParameter = window.location.search.toLowerCase().includes("fullsite=true");
  const [fullsite, setFullsite] = useSessionStorage("fullsite", fullsiteParameter);
  if (fullsiteParameter && !fullsite) {
    setFullsite(true);
  }

  function RunApp() {
    return (
      <BrowserRouter>
        <AppProvider>
          <AlertProvider template={AlertTemplate} {...alertOptions}>
            <MainLayout>
              <Switch>
                <Route exact path="/" component={Home} />
                <Redirect exact from="/Search" to="/" />
                <Route path="/version" exact component={Version} />
                <Route path="/legal" exact component={Legal} />
                <Route path="/Cart" component={ShoppingCart} />
                <Route path="/Checkout" component={Checkout} />
                <Route path="/About" component={About} />
                <Route path="/Faq" component={Faq} />
                <Route path="/Cpc" component={Cpc} />
                <Route path="/Csr" component={Csr} />
                <Route path="/Policies" component={Policies} />
                <Route path="/Returns" component={Returns} />
                <Route path="/SalesTax" component={SalesTax} />
                <Route path="/Privacy" component={Privacy} />
                <Route path="/Terms" component={Terms} />
                <Route path="/Contact" component={Contact} />
                <Route path="/Thankyou" component={Thankyou} />
                <Route path="/Login" component={Login} />
                <Route exact path="/Part/:part" component={PartPage} />
                <Route exact path="/Part/:part/:page" component={PartPage} />
                <Route exact path="/Page/:page" component={CatalogPage} />
                <Route exact path="/Search/:query" component={PartList} />
                <Route exact path="/Custom/Gasket" component={ProfileSelector} />
                <Route exact path="/Custom/Gasket/:part" component={GasketWizard} />
                <Route exact path="/Custom/Wire" component={WireWizard} />
                <Route exact path="/Custom/Wire/:part" component={WireWizard} />
                <Route exact path="/Custom/" component={CustomParts} />
                <Route exact path="/Custom/WireShelf" component={ShelfWizard} />
                <Route exact path="/Custom/Board" component={BoardWizard} />
                <Route exact path="/Custom/Heater" component={HeaterWizard} />
                <Route exact path="/Custom/Door" component={DoorWizard} />
                <Route exact path="/Dash" component={RequestDashboard} />
                <Route exact path="/Dash/:requestId" component={RequestDashboard} />
                <Route exact path="/Requests" component={RequestsChart} />
                <Route exact path="/Orders" component={OrdersChart} />
                <Route exact path="/Request/:requestId" component={RequestStatus} />
                <Route exact path="/RequestsReport" component={RequestsReport} />
                <Route exact path="/OrdersReport" component={OrdersReport} />
                <Route exact path="/OrdersReport/:view" component={OrdersReport} />
                <Redirect exact from="/Catalog/:section/:page" to="/Page/:page" />
                <Redirect exact from="/Catalog/:section/:page/:query" to="/Page/:page\?query=:query" />
                <Route exact path="/sms-opt-in" component={SmsConsent} />
                <Route path="/404" component={NotFound} />
                <Redirect from="*" to="/404" />
              </Switch>
            </MainLayout>
          </AlertProvider>
        </AppProvider>
      </BrowserRouter>
    );
  }


  // Redirect mobile devices to the mobile site, unless they requested the full site
  if (isMobile && !fullsite && !fullsiteParameter) {
    const current = window.location.href
    const domain = current.substring(8)
    const route = domain.substring(domain.indexOf('/'))
    window.location.href = AppSettings.MobileSite + route;
  } else if (readyToLoad) {
    return RunApp();
  } else {
    setReadyToLoad(true);
  }
}
